import React from 'react';
import quote from '../../assets/images/quote.svg';
import Image from 'next/image';

import { NextPage } from 'next';

interface Props {
    ngClass?: string;
}

const Quote: NextPage<Props> = ({ ngClass }) => {

    return (
        <div className='relative lg:min-h-[580px]'>
            <div className='globe-section lg:w-[1080px] lg:h-[580px] left-0 absolute z-10 hidden lg:block'></div>
            
            <div className={`max-w-7xl mx-auto flex flex-wrap my-0 h-[480px]`}>
                <div className='w-full lg:w-[55%] h-auto'>

                </div>
                <div className='w-full lg:w-[45%] flex flex-wrap gap-y-10 my-16 lg:my-24 py-10 px-4 md:pr-[6rem]'>
                    <div className='w-auto h-auto'>
                        <Image  src={quote} alt="quote-icon" height={80} width={80} />
                    </div>
                    <p className='text-white text-sub-header'>
                       With Virtual Gravity, any Planet can be your playground.
                    </p>
                    <div className='mt-2'>
                        <span className='text-orange italic text-sub-header font-bold'>Team Virtual Gravity</span>
                    </div>
                    <div className="quote-background">
                        <div className="stars"></div>
                        <div className="stars"></div>
                        <div className="stars"></div>
                        <div className="stars"></div>
                        <div className="stars"></div>
                    </div>
                </div>
            </div>
            {/* <div className='flex justify-center pt-4 h-[60px]'>
                <Image  className='animate-bounce' src={chevronDown} alt="chevron-down" />
            </div> */}
        </div>
    )
}

export default Quote